<template>
  <div class="subject" :class="{ 'subject--is-expanded': detailIsExpanded }">
    <div class="subject__index d-flex align-items-center">
      <span class="subject__sequence">第 {{ index + 1 }} 題</span>
      <i :class="subjectIcon" class="mdi mdi-18px ml-3"></i>
    </div>

    <div class="subject__title">
      <b-form-input ref="titleInputRef" v-model="vModelForTitle" @input="value => $emit('update:title', value)" :disabled="isReadOnly"></b-form-input>
    </div>

    <div class="subject__sorting d-flex align-items-center">
      <i class="mdi mdi-24px mdi-drag-horizontal cursor-grab subject-layout-drag-handler"></i>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveUpIsDisabled || isReadOnly }" variant="secondary" @click="$emit('top')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-collapse-up"></span>
        移至最頂
      </b-button>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveUpIsDisabled || isReadOnly }" variant="secondary" @click="$emit('up')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-up"></span>
        上移
      </b-button>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveDownIsDisabled || isReadOnly }" variant="secondary" @click="$emit('down')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-down"></span>
        下移
      </b-button>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveDownIsDisabled || isReadOnly }" variant="secondary" @click="$emit('bottom')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-collapse-down"></span>
        移至最底
      </b-button>
    </div>

    <div class="subject__deletion d-flex align-items-center">
      <b-button class="subject__button" variant="danger" @click="confirmForDeletion" :disabled="isReadOnly">
        <span class="mdi mdi-delete"></span>
        移除
      </b-button>
    </div>

    <div class="subject__options" v-if="detailIsExpanded">
      <b-input-group class="subject__advance-option-input" v-if="placeholderIsVisible">
        <b-input-group-prepend>
          <span class="mdi mdi-information-outline"></span>
        </b-input-group-prepend>
        <b-form-input ref="placeholderRef" v-model="vModelForPlaceholder" @input="value => $emit('update:placeholder', value)"></b-form-input>
      </b-input-group>
      <b-input-group class="subject__advance-option-input" v-if="memoIsVisible">
        <b-input-group-prepend>
          <span class="mdi mdi-comment-text-outline"></span>
        </b-input-group-prepend>
        <b-form-input ref="memoRef" v-model="vModelForMemo" @input="value => $emit('update:memo', value)"></b-form-input>
      </b-input-group>
      <div>
        <b-button class="mr-2 subject__button" :variant="vModelForRequired ? 'primary' : 'secondary'" @click="vModelForRequired = !vModelForRequired; $emit('update:required', vModelForRequired)" :disabled="isReadOnly">
          <span class="mdi mdi-star-outline"></span>
          必填
        </b-button>
        <b-button class="mr-2 subject__button" :variant="placeholderIsVisible ? 'primary' : 'secondary'" @click="togglePlaceholderValue" v-if="hasPlaceholder" :disabled="isReadOnly">
          <span class="mdi mdi-information-outline"></span>
          提示文字
        </b-button>
        <b-button class="mr-2 subject__button" :variant="memoIsVisible ? 'primary' : 'secondary'" @click="toggleMemoValue" :disabled="isReadOnly">
          <span class="mdi mdi-comment-text-outline"></span>
          填寫說明
        </b-button>
        <b-button v-if="formType === 'booking'" class="mr-2 subject__button" :variant="vModelForDashboardOnly ? 'primary' : 'secondary'" @click="toggleDashboardOnly" :disabled="isReadOnly">
          <span class="mdi mdi-laptop"></span>
          僅限後台編輯
        </b-button>
        <b-button v-if="formType === 'collection'" class="mr-2 subject__button" :variant="vModelForMainDisplay ? 'primary' : 'secondary'" @click="toggleMainDisplay" :disabled="isReadOnly">
          <span class="mdi mdi-label-outline"></span>
          下拉選單以這欄位當作顯示文字
        </b-button>
        <b-button v-if="formType === 'booking'" class="mr-2 subject__button" :variant="vModelForListHidden ? 'primary' : 'secondary'" @click="toggleListHidden" :disabled="isReadOnly">
          <span class="mdi mdi-eye-off"></span>
          隱藏於列表欄位
        </b-button>
      </div>
    </div>

    <div class="subject__preview" v-if="detailIsExpanded">
      <span class="font-weight-bold">預覽：</span>
      <b-card class="rounded rounded-lg subject__preview-box">
        <slot name="preview"></slot>
      </b-card>
    </div>

    <div class="subject__slot" v-if="detailIsExpanded">
      <slot></slot>
    </div>

    <div class="subject__expand">
      <b-button class="d-flex align-items-center justify-content-center w-100 border-0 bg-white" @click="toggleDetails">
        <i class="mdi mdi-24px mdi-chevron-down"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    memo: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    dashboardOnly: {
      type: Boolean,
      default: false,
    },
    mainDisplay: {
      type: Boolean,
      default: false,
    },
    listHidden: {
      type: Boolean,
      default: false,
    },
    moveUpIsDisabled: {
      type: Boolean,
      default: false,
    },
    moveDownIsDisabled: {
      type: Boolean,
      default: false,
    },
    hasPlaceholder: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    formType: {
      type: String,
      default: 'survey',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailIsExpanded: false,
      vModelForTitle: '',
      vModelForRequired: false,
      vModelForMemo: '',
      vModelForPlaceholder: '',
      memoIsVisible: false,
      placeholderIsVisible: false,
      vModelForDashboardOnly: false,
      vModelForMainDisplay: false,
      vModelForListHidden: false,
      titleInputRef: null,
    }
  },
  computed: {
    subjectIcon () {
      switch (this.type) {
        case 'text':                return 'mdi-format-title'
        case 'textarea':            return 'mdi-format-size'
        case 'radio':               return 'mdi-radiobox-marked'
        case 'checkbox':            return 'mdi-checkbox-marked-outline'
        case 'select':              return 'mdi-chevron-down'
        case 'collection_select':   return 'mdi-file-tree'
        default:                    return 'mdi-format-title'
      }
    },
    isReadOnly() {
      return this.readonly
    },
  },
  mounted() {
    this.vModelForTitle = this.title
    this.vModelForRequired = this.required
    this.vModelForMemo = this.memo
    this.vModelForPlaceholder = this.placeholder
    this.vModelForDashboardOnly = this.dashboardOnly
    this.vModelForMainDisplay = this.mainDisplay
    this.vModelForListHidden = this.listHidden

    this.memoIsVisible = !!this.vModelForMemo
    this.placeholderIsVisible = !!this.vModelForPlaceholder
    this.detailIsExpanded = this.expanded
  },
  methods: {
    async confirmForDeletion() {
      const consented = await this.$swal({
        title: this.title ? `確認刪除【${this.title}】嗎？` : '確認刪除嗎？',
        // text: ,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonColor: "#3085d6",
        cancelButtonText: "不刪除",
        confirmButtonColor: "#d33",
        confirmButtonText: "確定刪除",
        reverseButtons: false,
      })

      if (!consented.value) {
        return
      }

      this.$emit('remove')
    },
    toggleMemoValue() {
      if (this.memoIsVisible) {
        this.vModelForMemo = ''
        this.$emit('update:memo', '')
      }

      this.memoIsVisible = !this.memoIsVisible

      this.$nextTick(() => {
        if (this.memoIsVisible) {
          this.$refs.memoRef.focus()
        }
      })
    },
    togglePlaceholderValue() {
      if (this.placeholderIsVisible) {
        this.vModelForPlaceholder = ''
        this.$emit('update:placeholder', '')
      }

      this.placeholderIsVisible = !this.placeholderIsVisible

      this.$nextTick(() => {
        if (this.placeholderIsVisible) {
          this.$refs.placeholderRef.focus()
        }
      })
    },
    toggleDetails() {
      this.detailIsExpanded = !this.detailIsExpanded
      this.$emit('update:expanded', this.detailIsExpanded)
    },
    toggleDashboardOnly() {
      this.vModelForDashboardOnly = !this.vModelForDashboardOnly;
      this.$emit('update:dashboardOnly', this.vModelForDashboardOnly);
    },
    toggleMainDisplay() {
      this.vModelForMainDisplay = !this.vModelForMainDisplay;
      this.$emit('update:mainDisplay', this.vModelForMainDisplay);
    },
    toggleListHidden() {
      this.vModelForListHidden = !this.vModelForListHidden;
      this.$emit('update:listHidden', this.vModelForListHidden);
    },
  }
}
</script>

<style lang="scss" scoped>
.subject {
  display: grid;
  grid-template-columns: 114px auto 350px 60px;
  grid-gap: 0 16px;
  grid-template-areas:
    "index  title   sorting deletion"
    ".      options preview preview"
    "slot   slot    preview preview"
    "expand expand  expand  expand";

  $sections: index, title, sorting, deletion, options, preview, slot, expand;
  @each $sectionName in $sections {
    &__#{$sectionName} {
      grid-area: $sectionName;
    }
  }

  &--is-expanded {
    .subject__expand i {
      transform: rotate(180deg);
    }
  }

  .subject__sequence {
    border-radius: 4px;
    padding: 4px 12px;
    background-color: #eee;
    font-size: .875rem;
  }
  &__options {
    margin-top: 16px;
  }
  &__preview {
    margin-top: 16px;
  }
  &__advance-option-input {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: #eee;

    .input-group-prepend {
      background-color: transparent;
    }

    span.mdi {
      margin-left: 12px;
    }

    input.form-control {
      border: none;
      background-color: transparent;
    }
  }

  &__button {
    padding: 4px 6px;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }

  .subject__preview-box {
    background-color: #e7e7e7;
  }
}
</style>
